import { Accordeon, AccordeonItem } from '@uc-common/b-accordeon';
import { Columns, ColumnItemCardImage, ColumnItem } from '@uc-common/b-columns';
import { Head, HeadVariantsEnum } from '@uc-common/b-head';
import { Icon } from '@uc-common/b-icon';
import { Layout as BLayout, PaddingEnum, Section } from '@uc-common/b-layout';
import {
  LeadForm,
  LeadFormCheckboxField,
  LeadFormFieldKindEnum,
  LeadFormPrivacyPolicyLink,
  LeadFormTextField,
} from '@uc-common/b-leadform';
import { FORM_ID_ENUM } from '@uc-common/use-lead-form';
import {
  ListWithVisual,
  ListWithVisualIllustration,
  ListWithVisualItem,
} from '@uc-common/b-list-with-visual';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import React from 'react';
import { Box, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Link } from '~/src/components/Link';
import { CommonLayout } from '../../../components/Layout/CommonLayout';
import { Navbar, NavbarModesEnum } from '../../../components/Navbar';
import { Footer } from '../../../components/Footer';

export const frontmatter = {
  title: 'Free enterprise-level media pipeline for startups',
  description:
    'Get a ready-made infrastructure for uploading, processing, and delivering media content, and use it free of charge until your company is up and running.',
  noindex: false,
};

const TermsLink = styled.a`
  text-decoration: none;

  &,
  &:active,
  &:visited,
  &:visited:active {
    color: #333;
  }

  &:hover,
  &:focus,
  &:visited:hover,
  &:visited:focus {
    color: black;
  }
`;

const Startups = ({ location }) => {
  return (
    <CommonLayout meta={frontmatter} pathName={location.pathname}>
      <BLayout header={<Navbar mode={NavbarModesEnum.PPC} />} footer={<Footer />}>
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_S,
          }}
        >
          <Head
            variant={HeadVariantsEnum.V1}
            title="Free enterprise-level media pipeline for&nbsp;startups"
            description="Get a ready-made infrastructure for uploading, processing, and&nbsp;delivering media content, and use it free of charge until your&nbsp;company is up and running."
            cta={
              <Button as={Link} to="#leadform" type={ButtonTypeEnum.DARK}>
                Apply now
              </Button>
            }
          />
        </Section>

        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_M,
          }}
        >
          <Columns sx={{ paddingTop: PaddingEnum.VERTICAL_M }}>
            <ColumnItemCardImage
              title="Easy file uploads"
              description="The file uploading widget seamlessly plugs into your interface, allowing you to easily and safely accept user-generated content.              "
              imageSrc="https://ucarecdn.com/a915c69f-e2e7-4478-ac22-85aa0fc26b56/fileuploads.svg"
            />
            <ColumnItemCardImage
              title="Smart processing"
              description="Every uploaded image is automatically optimized and made adaptive thanks to&nbsp;the&nbsp;Uploadcare AI-driven engine. Need further changes? Modify on the fly by&nbsp;adding commands to the URL."
              imageSrc="https://ucarecdn.com/e2dfc1a2-38d9-4f7a-81fc-82628ded5f93/imageprocessing.svg"
            />
            <ColumnItemCardImage
              title="Lightning-fast delivery"
              description="Thanks to the distributed network of&nbsp;288,000 servers across the globe, users get the best load times regardless of where they are.
              "
              imageSrc="https://ucarecdn.com/2af16478-474f-44c6-a65b-3f58391c64ce/worldwidecdn.svg"
            />
          </Columns>
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <ListWithVisual
            title="Go enterprise without spending a&nbsp;cent"
            illustration={
              <ListWithVisualIllustration src="https://ucarecdn.com/b37368e9-10be-4ec0-a002-bb23a3b8602e/ideatoprod3hr.svg" />
            }
            cta={
              <Button as={Link} to="#leadform" type={ButtonTypeEnum.DARK}>
                Apply now
              </Button>
            }
            items={
              <>
                <ListWithVisualItem
                  icon={<Icon id="chip-boxed" />}
                  title="288,000 CDN nodes worldwide"
                  description="With multiple data centers around the&nbsp;world, we ensure minimum page load time, no matter the user’s location."
                />
                <ListWithVisualItem
                  icon={<Icon id="code-boxed" />}
                  title="Handles 500M request per day"
                  description="The infrastructure is ready for any number of daily requests for file uploads, manipulations, and deliveries."
                />
                <ListWithVisualItem
                  icon={<Icon id="shield-boxed" />}
                  title="99.99% uptime guaranteed"
                  description="All of your uploads go to the Uploadcare smart storage that’s covered by an SLA and 99.9% uptime."
                />
              </>
            }
          />
        </Section>
        <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
          <Head
            variant={HeadVariantsEnum.V2}
            title="Four simple steps to get up and running with Uploadcare"
            description=""
          />
          <Accordeon sx={{ paddingTop: PaddingEnum.VERTICAL_L }}>
            <AccordeonItem
              title="Schedule a demo"
              description="We’d love to learn more about your startup, project status and milestones. Tell us which resources you need, and you’ll have them."
              illustrationSrc="https://ucarecdn.com/b5ae8be9-a402-4bca-8d39-fb29fb20d1fa/fourclock01.svg"
            />
            <AccordeonItem
              title="Develop your startup"
              description="We provide all the necessary infrastructure for&nbsp;your early launch."
              illustrationSrc="https://ucarecdn.com/97459baa-b1dc-4d55-ad5f-fae440ace4d7/fourclock02.svg"
            />
            <AccordeonItem
              title="Launch"
              description="After the launch, take advantage of a special price offering."
              illustrationSrc="https://ucarecdn.com/1d61bab2-46a5-49fb-8095-c589484561ff/fourclock03.svg"
            />
            <AccordeonItem
              title="Scale"
              description="We grow with you, and so does the&nbsp;infrastructure."
              illustrationSrc="https://ucarecdn.com/6786a41b-02e7-4009-b854-91b99b585b69/fourclock04.svg"
            />
          </Accordeon>
        </Section>
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_XL,
          }}
        >
          <Columns>
            <ColumnItem
              title="What’s included?"
              description="The Uploadcare Scale plan includes a&nbsp;ready-made infrastructure for uploading, processing, and delivering media content, and 800&nbsp;GB of&nbsp;bandwidth usage monthly."
            />
            <ColumnItem
              title="How much does it&nbsp;cost?"
              description="The regular price for the Scale plan is&nbsp;$385/mo. As&nbsp;a&nbsp;startup from a&nbsp;trusted accelerator, you&rsquo;ll get FREE access to&nbsp;all its capabilities which will be&nbsp;valid for six months."
            />
            <ColumnItem
              title="What’s next?"
              description="Upon expiration of&nbsp;the discounted period, you can stay on&nbsp;this plan, switch to&nbsp;another one, or&nbsp;choose to&nbsp;stop using Uploadcare without any penalties."
            />
          </Columns>
        </Section>
        <Section
          id="leadform"
          sx={{
            paddingTop: PaddingEnum.VERTICAL_XL,
            paddingBottom: PaddingEnum.VERTICAL_L,
          }}
        >
          <LeadForm
            id={FORM_ID_ENUM.STARTUP_DISCOUNT}
            title="Unleash the power of Uploadcare"
            description="And save over $2,000 on infrastructure"
            illustrationSrc="https://ucarecdn.com/8c1e0039-4913-45eb-8412-ddc6613330dd/startuptolyaquote.svg"
            formTitle="Apply now!"
            formCta="Apply now"
          >
            <LeadFormTextField kind={LeadFormFieldKindEnum.NAME} placeholder="Full name" />
            <LeadFormTextField kind={LeadFormFieldKindEnum.WEBSITE} placeholder="Company website" />
            <LeadFormTextField kind={LeadFormFieldKindEnum.EMAIL} placeholder="Email" />
            <LeadFormTextField
              kind={LeadFormFieldKindEnum.JOB}
              required={false}
              placeholder="Job title"
            />
            <LeadFormTextField
              kind={LeadFormFieldKindEnum.CUSTOM}
              name="Accelerator"
              placeholder="Name of the Accelerator"
            />
            <LeadFormCheckboxField
              kind={LeadFormFieldKindEnum.PRIVACY_POLICY_CHECKBOX}
              label={
                <>
                  I agree to the{' '}
                  <LeadFormPrivacyPolicyLink>Privacy Policy</LeadFormPrivacyPolicyLink>{' '}
                </>
              }
            />
          </LeadForm>
        </Section>
        <Section
          sx={{
            paddingTop: PaddingEnum.VERTICAL_S,
            paddingBottom: PaddingEnum.VERTICAL_XL,
          }}
        >
          <Box sx={{ px: PaddingEnum.HORIZONTAL_WIDE }}>
            <Text
              as="p"
              fontSize={['18px']}
              lineHeight="1.6em"
              textAlign="left"
              pt="8px"
              fontWeight="normal"
              color="rgb(98, 98, 98)"
            >
              The program participation rules are regulated by the Uploadcare{' '}
              <TermsLink target="_blank" href="https://uploadcare.com/about/terms/">
                Terms of Service
              </TermsLink>
              .
            </Text>
          </Box>
        </Section>
      </BLayout>
    </CommonLayout>
  );
};

export default Startups;
